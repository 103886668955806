<template>
  <div :class="['render-template', 'render-template--inline']">
    <template v-for="(part, idx) in displayTemplateParts" :key="idx">
      <DataNull
        v-if="
          part === null ||
          (typeof part === 'object' && part.item.getDataProperty(part.fieldKey) === null)
        "
      />

      <Suspense v-else-if="part !== null && typeof part === 'object'">
        <component
          :is="part.component"
          v-bind="{
            collectionName: props.collectionName,
            item: part.item,
            fieldKey: part.fieldKey,
            context: props.context,
            fieldInfo: part.fieldInfo || undefined,
          }"
        ></component>

        <template #fallback>
          <Skeleton height="2rem" width="5rem"></Skeleton>
        </template>
      </Suspense>

      <span v-else>{{ part }}</span>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { watchEffect, shallowRef } from "vue";
  import Skeleton from "primevue/skeleton";
  import type { FieldDisplayOptions } from "~/api/field-displays/types";
  import type { IField } from "~/entities/field";
  import DataNull from "~/components/DataNull/data-null.vue";
  import type { IItem } from "~/entities/item";
  import { useDisplayTemplateParts } from "../model";

  interface Props {
    collectionName: string;
    item: IItem;
    fieldInfo: IField;
    defaultTemplate?: string;
    context?: {
      data?: IItem;
    };
  }

  const props = defineProps<Props>();

  const displayTemplateParts = shallowRef<
    (FieldDisplayOptions | string | null)[] | undefined
  >(undefined);

  watchEffect(() => {
    displayTemplateParts.value = useDisplayTemplateParts(
      props.defaultTemplate || `{{ ${props.fieldInfo.name} }}`,
      props.collectionName,
      props.item,
      props.fieldInfo,
    );
  });
</script>

<style scoped></style>
